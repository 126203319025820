<template>
  <div class="borderColor borderColorBackground mb-4">
    <div class="router-links d-flex align-items-center mb-3">
      <div
        class="vol-income datepickerButton"
        :class="{ active: order === 1 }"
        @click="
          getcsv('_串列資料.json');
          order = 1;
        "
      >
        串接資料
      </div>
      <div
        class="transform datepickerButton"
        :class="{ active: order === 2 }"
        @click="
          getcsv('_PVSYS.json');
          order = 2;
        "
      >
        PVsyst
      </div>
      <div
        class="transform datepickerButton"
        :class="{ active: order === 3 }"
        @click="
          getPdf();
          order = 3;
        "
      >
        檔案資料
      </div>
      <div
        class="transform datepickerButton"
        :class="{ active: order === 4 }"
        @click="
          getKpiTable();
          order = 4;
          resError = false;
        "
        v-if="true"
      >
        KPI設定
      </div>
      <span
        style="margin-left: auto; align-items: center"
        v-if="!resError && order !== 3"
      >
        <span v-if="order === 4">
          <span style="margin: 5px"
            >基礎PR衰退率:
            <input
              min="0"
              type="number"
              style="width: 100px"
              v-model="basicDeclineRate"
          /></span>
          <span style="margin: 5px"
            >逐年PR衰退率:
            <input
              min="0"
              type="number"
              style="width: 100px"
              v-model="declineRate"
          /></span>
          <span style="margin: 5px"
            >新增幾年:
            <input
              min="1"
              type="number"
              style="width: 50px"
              v-model="newDataLength"
          /></span>
          <button
            class="confirmButton"
            style="width: 80px"
            @click="kpiSettingNewData"
          >
            新增
          </button>
          <!-- <button class="btn downlaodbuttonstyle btn-secondary" style="margin:5px; width:70px" @click="updateKpiSetting">上傳</button> -->
        </span>
        <button
          class="confirmButton"
          style="margin: 0px 5px; width: 70px"
          @click="changePpt = !changePpt"
        >
          {{ changePpt ? "確認" : "修改" }}
        </button>
        <span>
          <b-button
            v-if="order !== 3"
            v-b-tooltip.hover
            :title="changePpt ? '請確認修改資料' : ''"
            class="confirmButton"
            style="width: 70px"
            :style="{ cursor: changePpt ? 'not-allowed' : 'pointer' }"
            :disabled="changePpt"
            @click="
              order === 1 || order === 2
                ? updateCsv()
                : order === 4
                ? updateKpiSetting()
                : ''
            "
          >
            上傳
          </b-button>
        </span>
      </span>
    </div>
    <div style="text-align: center; font-weight: 700" v-if="order === 4">
      PR衰退公式 => 起始年PR - (((基礎PR衰退率 + ((第幾年 - 1) * 逐年PR衰退率)))
      * 起始年PR)
    </div>

    <div style="overflow: auto" class="whiteBackground">
      <b-alert
        v-if="resError"
        show
        variant="success"
        style="text-align: center; margin: 5px; font-weight: 700"
      >
        目前暫無資料，待資料上傳
      </b-alert>

      <!-- PVsyst table -->
      <table class="padding-small box-border" v-if="order !== 3 && order !== 4">
        <tr>
          <th v-for="(i, idx) in tableHeader" :key="idx">
            <input
              v-if="changePpt"
              :value="i.indexOf('__EMPTY') === -1 ? i : ''"
              style="text-align: center; width: 100px"
              @input="
                changeTableTitle($event.target.value, idx, tableHeader[idx])
              "
            />
            <span v-else>{{ i.indexOf("__EMPTY") === -1 ? i : "" }}</span>
          </th>
        </tr>
        <tr v-for="(i, idx) in tableData" :key="idx">
          <td v-for="(j, idx) in tableHeader" :key="idx">
            <input
              v-if="changePpt"
              :value="i[j]"
              style="text-align: center; width: 100px"
              @input="i[j] = $event.target.value"
            />
            <span v-else>{{ i[j] }}</span>
          </td>
        </tr>
      </table>

      <!-- 檔案資料 table -->
      <table v-if="order === 3 && pdfArray.length != 0">
        <tr>
          <th>檔名</th>
          <th v-if="dataAdmin.includes(userDetail.role)">刪除</th>
        </tr>
        <tr v-for="(i, idx) in pdfArray" :key="idx">
          <td>
            <a :href="i.value" target="_blank">{{ i.name }}</a>
          </td>
          <td v-if="dataAdmin.includes(userDetail.role)">
            <button
              class="deleteButton"
              style="margin: 5px; width: 70px"
              @click="checkDeletePdf(i.name)"
            >
              X
            </button>
          </td>
        </tr>
      </table>

      <!-- KPI 設定 button -->
      <div
        v-if="order === 4"
        class="d-flex align-items-center mt-2"
        style="margin-bottom: -25px"
      >
        <label class="btn btn-sm btn-info m-0 mr-2">
          <i class="fas fa-file-upload" />&ensp;KPI 資料輸入
          <input
            type="file"
            class="border border-black rounded d-none"
            accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="handleUploadInputKpi"
          />
        </label>
        <div
          v-if="uploadKpiFileName"
          class="text-secondary"
          style="font-size: 14px"
        >
          {{ uploadKpiFileName }}
        </div>
      </div>

      <!-- KPI 設定 table -->
      <div class="kpiSettingTable" v-if="order === 4">
        <div v-for="(i, idx) in kpiSetting" :key="idx" class="mt-5">
          <div class="d-flex align-items-center justify-content-between">
            <b-form-checkbox
              :id="String(idx)"
              v-model="kpiSettingManualList[idx]"
              class="ml-1"
            >
              啟用手動設定
            </b-form-checkbox>

            <div
              style="color: gray; text-align: right; margin-bottom: 5px"
              v-if="kpiSetting.length > 1"
            >
              <button
                class="deleteButton"
                style="color: gray"
                @click="kpiSettingSplice(idx)"
              >
                移除
              </button>
            </div>
          </div>

          <div class="kpiTableWrap">
            <table>
              <tr>
                <th>
                  <span v-if="!changePpt">{{ i.year }}</span>
                  <input
                    v-else
                    :value="i.year"
                    style="text-align: center; width: 100px"
                    @input="i.year = $event.target.value"
                  />
                </th>
                <th v-for="i in monthArray" :key="i" style="color: gray">
                  {{ i }}
                </th>
              </tr>
              <tr>
                <td>目標 PR</td>
                <td v-for="(x, idx) in i.PR" :key="idx">
                  <span v-if="!changePpt">{{ x }}</span>
                  <input
                    v-else
                    :value="x"
                    style="width: 80px; text-align: center"
                    @input="i.PR[idx] = Number($event.target.value)"
                  />
                </td>
              </tr>
              <tr>
                <td>妥善率</td>
                <td v-for="(x, idx) in i.properRate" :key="idx">
                  <span v-if="!changePpt">{{ x }}</span>
                  <input
                    v-else
                    :value="x"
                    style="width: 80px; text-align: center"
                    @input="i.properRate[idx] = Number($event.target.value)"
                  />
                </td>
              </tr>
              <tr>
                <td>峰瓩值</td>
                <td v-for="(x, idx) in i.peakWatt" :key="idx">
                  <span v-if="!changePpt">{{ x }}</span>
                  <input
                    v-else
                    :value="x"
                    style="width: 80px; text-align: center"
                    @input="i.peakWatt[idx] = Number($event.target.value)"
                  />
                </td>
              </tr>
              <tr>
                <td>保證 PR</td>
                <td v-for="(x, idx) in i.guaranteePr" :key="idx">
                  <span v-if="!changePpt">{{ x }}</span>
                  <input
                    v-else
                    :value="x"
                    style="width: 80px; text-align: center"
                    @input="i.guaranteePr[idx] = Number($event.target.value)"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-nowrap">保證發電量</td>
                <td v-for="(x, idx) in i.guaranteePower" :key="idx">
                  <span v-if="!changePpt">{{ x }}</span>
                  <input
                    v-else
                    :value="x"
                    style="width: 80px; text-align: center"
                    @input="i.guaranteePower[idx] = Number($event.target.value)"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-nowrap">目標發電量</td>
                <td v-for="(x, idx) in i.acpGoalContract" :key="idx">
                  <span v-if="!changePpt">{{ x }}</span>
                  <input
                    v-else
                    :value="x"
                    style="width: 80px; text-align: center"
                    @input="
                      i.acpGoalContract[idx] = Number($event.target.value)
                    "
                  />
                </td>
              </tr>

              <template v-if="kpiSettingManualList[idx]">
                <tr>
                  <td class="text-nowrap">設定&ensp;[ 日照量 ]</td>
                  <td v-for="(x, idx) in i.irrActualManual" :key="idx">
                    <span v-if="!changePpt">{{ x }}</span>
                    <input
                      v-else
                      :value="x"
                      style="width: 80px; text-align: center"
                      @input="
                        i.irrActualManual[idx] = Number($event.target.value)
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">設定&ensp;[ 發電量 ]</td>
                  <td v-for="(x, idx) in i.acpActualManual" :key="idx">
                    <span v-if="!changePpt">{{ x }}</span>
                    <input
                      v-else
                      :value="x"
                      style="width: 80px; text-align: center"
                      @input="
                        i.acpActualManual[idx] = Number($event.target.value)
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">設定&ensp;[ PR ]</td>
                  <td v-for="(x, idx) in i.PRActualManual" :key="idx">
                    <span v-if="!changePpt">{{ x }}</span>
                    <input
                      v-else
                      :value="x"
                      style="width: 80px; text-align: center"
                      @input="
                        i.PRActualManual[idx] = Number($event.target.value)
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">設定&ensp;[ Data_down ]</td>
                  <td
                    v-for="(x, idx) in i.availabilityManual?.data_down"
                    :key="idx"
                  >
                    <span v-if="!changePpt">{{ x }}</span>
                    <input
                      v-else
                      :value="x"
                      style="width: 80px; text-align: center"
                      @input="
                        i.availabilityManual.data_down[idx] = Number(
                          $event.target.value
                        )
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">設定&ensp;[ T_useful ]</td>
                  <td
                    v-for="(x, idx) in i.availabilityManual?.t_useful"
                    :key="idx"
                  >
                    <span v-if="!changePpt">{{ x }}</span>
                    <input
                      v-else
                      :value="x"
                      style="width: 80px; text-align: center"
                      @input="
                        i.availabilityManual.t_useful[idx] = Number(
                          $event.target.value
                        )
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">設定&ensp;[ T_down ]</td>
                  <td
                    v-for="(x, idx) in i.availabilityManual?.t_down"
                    :key="idx"
                  >
                    <span v-if="!changePpt">{{ x }}</span>
                    <input
                      v-else
                      :value="x"
                      style="width: 80px; text-align: center"
                      @input="
                        i.availabilityManual.t_down[idx] = Number(
                          $event.target.value
                        )
                      "
                    />
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utils, read } from 'xlsx'
import {
  createKpiTableApi,
  getKpiTableApi,
  getPdfApi,
  getCsvApi,
  updateCsvApi,
  deletePdfApi
} from '../api'
import { BAlert, BButton } from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BAlert,
    BButton
  },
  data () {
    return {
      basicDeclineRate: 0.03,
      declineRate: 0.007,
      newDataLength: 1,
      monthArray: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      uploadKpiFile: undefined,
      uploadKpiFileName: '',
      kpiSetting: [
        {
          year: '2021-預設值請修改',
          PR: [80, 82, 91, 80, 82, 91, 80, 82, 91, 80, 82, 91],
          properRate: Array(12).fill(98),
          peakWatt: [
            3000, 2500, 1000, 3000, 2500, 1000, 3000, 2500, 1000, 3000, 2500,
            1000
          ],
          guaranteePr: Array(12).fill(0),
          guaranteePower: Array(12).fill(0),
          acpGoalContract: Array(12).fill(0),
          irrActualManual: Array(12).fill(0),
          acpActualManual: Array(12).fill(0),
          PRActualManual: Array(12).fill(0),
          availabilityManual: {
            data_down: Array(12).fill(0),
            t_useful: Array(12).fill(0),
            t_down: Array(12).fill(0)
          }
        }
      ],
      kpiSettingByApi: [],
      kpiSettingManualList: [], // [true, false, true,... ]
      kpiSettingManualListByApi: [], // [true, false, true,... ]
      pdfArray: [],
      changePpt: false,
      tableData: [],
      tableHeader: [],
      csvName: '',
      resError: false,
      order: 1
    }
  },
  computed: {
    ...mapState(['dataAdmin', 'userDetail'])
  },
  methods: {
    handleUploadInputKpi (e) {
      if (e.target.files?.length === 0) {
        this.uploadKpiFile = undefined // 上傳後又取消，清除要上傳的暫存
        this.uploadKpiFileName = ''
        this.kpiSetting = [...this.kpiSettingByApi]
        this.kpiSettingManualList = [...this.kpiSettingManualListByApi]
        return
      }

      const [file] = e.target.files
      // check file name (file.name)
      const reader = new FileReader()

      // 讀取成 ArrayBuffer
      reader.readAsArrayBuffer(file)

      // 讀取成功
      reader.onloadend = (e) => {
        const data = e.target?.result
        const workbook = read(data, { type: 'array' })

        // year list // FIXME: 檢查檔案標題 不是年份，如果設定空的話會被略過
        const firstSheetName = workbook.SheetNames[0]
        const firstWorkSheet = workbook.Sheets[firstSheetName]
        // 檢查 '!ref' 是否存在
        const firstSheetRef = firstWorkSheet['!ref']
        if (!firstSheetRef) return this.$swal('工作表範圍未定義')
        let columnA = []
        const range = utils.decode_range(firstSheetRef) // 取得工作表的範圍
        for (let row = range.s.r; row <= range.e.r; row++) {
          // 遍歷所有行
          const cellAddress = `A${row + 1}` // 建立單元格地址，如 A1, A2, A3
          const cell = firstWorkSheet[cellAddress]
          // columnA.push(utils.format_cell(cell));
          columnA.push(cell?.v)
        }
        const yearList = columnA
          .filter((v) => v && v !== '西元年別')
          .map((v) => String(v))

        // deal with excel raw data
        const dataKeys = [
          'PR',
          'properRate',
          'peakWatt',
          'guaranteePr',
          'guaranteePower',
          'acpGoalContract'
        ]
        const dataKeyNames = [
          '目標PR',
          '妥善率',
          '峰瓩值',
          '保證PR',
          '保證發電量',
          '目標發電量'
        ]
        let allProcessedJsonData = {}
        dataKeyNames.forEach((sheetName, idx) => {
          const workSheet = workbook.Sheets[sheetName]
          const sheetJson = utils.sheet_to_json(workSheet)
          const processedJsonData = sheetJson.reduce((accu, curr) => {
            const year = curr['西元年別']
            accu[year] = [
              curr.Jan || 0,
              curr.Feb || 0,
              curr.Mar || 0,
              curr.Apr || 0,
              curr.May || 0,
              curr.Jun || 0,
              curr.Jul || 0,
              curr.Aug || 0,
              curr.Sep || 0,
              curr.Oct || 0,
              curr.Nov || 0,
              curr.Dec || 0
            ]

            return accu
          }, {})

          allProcessedJsonData[dataKeys[idx]] = processedJsonData
        })

        // final excel data
        const defaultData = Array(12).fill(0)
        const finalExcelData = yearList.map((year, _idx) => {
          return {
            year,
            PR: allProcessedJsonData?.PR?.[year] || defaultData,
            properRate: allProcessedJsonData?.properRate?.[year] || defaultData,
            peakWatt: allProcessedJsonData?.peakWatt?.[year] || defaultData,
            guaranteePr:
              allProcessedJsonData?.guaranteePr?.[year] || defaultData,
            guaranteePower:
              allProcessedJsonData?.guaranteePower?.[year] || defaultData,
            acpGoalContract:
              allProcessedJsonData?.acpGoalContract?.[year] || defaultData
          }
        })
        // console.log(finalExcelData)

        this.uploadKpiFile = finalExcelData
        this.uploadKpiFileName = file.name

        this.setUploadExcelToKpiSetting(this.uploadKpiFile)
      }

      // 讀取失敗
      reader.onerror = (err) => console.error('handleFileChange', err)
    },
    setUploadExcelToKpiSetting (uploadedExcelData) {
      // 原本資料有啟用手動設定 Map
      const originalManualMap = new Map()
      this.kpiSetting.forEach((originObj, originIdx) => {
        if (this.kpiSettingManualList[originIdx]) {
          return originalManualMap.set(originObj.year, originObj)
        }
      })

      this.kpiSetting = uploadedExcelData.map((obj, idx) => {
        if (originalManualMap.has(obj.year)) {
          this.kpiSettingManualList[idx] = !!originalManualMap.has(obj.year)
          const copyObj = originalManualMap.get(obj.year)
          return {
            ...copyObj,
            ...obj
          }
        }

        this.kpiSettingManualList[idx] = !!originalManualMap.has(obj.year)
        return {
          ...obj,
          irrActualManual: Array(12).fill(0),
          acpActualManual: Array(12).fill(0),
          PRActualManual: Array(12).fill(0),
          availabilityManual: {
            data_down: Array(12).fill(0),
            t_useful: Array(12).fill(0),
            t_down: Array(12).fill(0)
          }
        }
      })
    },
    kpiSettingSplice (idx) {
      this.kpiSetting.splice(idx, 1)
      this.kpiSettingManualList.splice(idx, 1)
    },
    kpiSettingNewData () {
      if (this.kpiSetting[0].year.split('-')[1] === '預設值請修改') {
        this.$swal('請先修改預設值')
        return
      }
      for (let i = 0; i < this.newDataLength; i++) {
        const lastLength = this.kpiSetting.length - 1
        const lastData = JSON.parse(
          JSON.stringify(this.kpiSetting[lastLength])
        )
        this.kpiSetting.push({
          ...lastData,
          irrActualManual: Array(12).fill(0),
          acpActualManual: Array(12).fill(0),
          PRActualManual: Array(12).fill(0),
          availabilityManual: {
            data_down: Array(12).fill(0),
            t_useful: Array(12).fill(0),
            t_down: Array(12).fill(0)
          }
        })
        this.kpiSetting[this.kpiSetting.length - 1].year = `${
          Number(this.kpiSetting[this.kpiSetting.length - 1].year) + 1
        }`
        const diffYear =
          this.kpiSetting[this.kpiSetting.length - 1].year -
          this.kpiSetting[0].year
        // PR衰退 第一年衰減3%, 第二年(含)以後各年衰減0.7%.  各年與初始值(第0年)相比的衰減幅度公式為  3%+(n-1)*0.7%,   n是第幾年
        let declinePr = JSON.parse(JSON.stringify(this.kpiSetting[0].PR))
        declinePr = declinePr.map((x) =>
          Math.round(
            x -
              (Number(this.basicDeclineRate) +
                (diffYear - 1) * Number(this.declineRate)) *
                x
          )
        )
        this.kpiSetting[this.kpiSetting.length - 1].PR = declinePr
      }
    },
    async updateKpiSetting () {
      if (this.kpiSetting[0].year.split('-')[1] === '預設值請修改') {
        this.$swal('請先修改預設值')
        return
      }

      // check 上傳 manual 值
      const updateKpiSettingData = this.kpiSetting.map((obj, idx) => {
        if (this.kpiSettingManualList[idx]) {
          return obj
        } else {
          const {
            year,
            PR,
            properRate,
            peakWatt,
            guaranteePr,
            guaranteePower,
            acpGoalContract
          } = obj
          return {
            year,
            PR,
            properRate,
            peakWatt,
            guaranteePr,
            guaranteePower,
            acpGoalContract
          }
        }
      })

      const updateKpi = await createKpiTableApi(
        `${this.$router.history.current.query.siteName}.json`,
        updateKpiSettingData
      )
      if (updateKpi?.data?.success) {
        this.$swal('上傳成功')
        this.getKpiTable()
      }
    },
    async getKpiTable () {
      const getKpi = await getKpiTableApi(
        `${this.$router.history.current.query.siteName}.json`
      )
      if (getKpi?.data?.success) {
        this.kpiSetting = getKpi.data.data.map((obj, idx) => {
          // 判斷 Manual 欄位
          // 有一 Manual 即其餘全有
          this.kpiSettingManualList[idx] = !!obj.acpActualManual

          // 有 data 但未滿 12 ---

          const MONTHS_IN_YEAR = 12
          if (
            obj.irrActualManual &&
            obj.irrActualManual.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength = MONTHS_IN_YEAR - obj.irrActualManual.length
            Object.assign(obj, {
              irrActualManual: [
                ...obj.irrActualManual,
                ...Array(fillArrayLength).fill(0)
              ]
            })
          }

          if (
            obj.acpActualManual &&
            obj.acpActualManual.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength = MONTHS_IN_YEAR - obj.acpActualManual.length
            Object.assign(obj, {
              acpActualManual: [
                ...obj.acpActualManual,
                ...Array(fillArrayLength).fill(0)
              ]
            })
          }

          if (
            obj.PRActualManual &&
            obj.PRActualManual.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength = MONTHS_IN_YEAR - obj.PRActualManual.length
            Object.assign(obj, {
              PRActualManual: [
                ...obj.PRActualManual,
                ...Array(fillArrayLength).fill(0)
              ]
            })
          }

          if (
            obj.availabilityManual?.data_down &&
            obj.availabilityManual?.data_down?.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength =
              MONTHS_IN_YEAR - obj.availabilityManual.data_down.length
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                data_down: [
                  ...obj.availabilityManual.data_down,
                  ...Array(fillArrayLength).fill(0)
                ]
              }
            })
          }

          if (
            obj.availabilityManual?.t_useful &&
            obj.availabilityManual?.t_useful?.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength =
              MONTHS_IN_YEAR - obj.availabilityManual.t_useful.length
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                t_useful: [
                  ...obj.availabilityManual.t_useful,
                  ...Array(fillArrayLength).fill(0)
                ]
              }
            })
          }

          if (
            obj.availabilityManual?.t_down &&
            obj.availabilityManual?.t_down?.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength =
              MONTHS_IN_YEAR - obj.availabilityManual.t_down.length
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                t_down: [
                  ...obj.availabilityManual.t_down,
                  ...Array(fillArrayLength).fill(0)
                ]
              }
            })
          }

          // 無 data ---

          if (!obj.guaranteePr) {
            Object.assign(obj, { guaranteePr: Array(12).fill(0) })
          }
          if (!obj.guaranteePower) {
            Object.assign(obj, { guaranteePower: Array(12).fill(0) })
          }
          if (!obj.acpGoalContract) {
            Object.assign(obj, { acpGoalContract: Array(12).fill(0) })
          }

          if (!obj.irrActualManual) {
            Object.assign(obj, { irrActualManual: Array(12).fill(0) })
          }
          if (!obj.acpActualManual) {
            Object.assign(obj, { acpActualManual: Array(12).fill(0) })
          }
          if (!obj.PRActualManual) {
            Object.assign(obj, { PRActualManual: Array(12).fill(0) })
          }
          if (!obj.availabilityManual?.data_down) {
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                data_down: Array(12).fill(0)
              }
            })
          }
          if (!obj.availabilityManual?.t_useful) {
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                t_useful: Array(12).fill(0)
              }
            })
          }
          if (!obj.availabilityManual?.t_down) {
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                t_down: Array(12).fill(0)
              }
            })
          }
          return obj
        })
      }

      // 另存 api 的資料 (api 有存 api 的，沒有存 this.kpiSetting)
      this.kpiSettingByApi = [...this.kpiSetting]
      this.kpiSettingManualListByApi = [...this.kpiSettingManualList]
    },
    changeTableTitle (s, idx, i) {
      const name = i
      this.tableHeader[idx] = s
      this.tableData.forEach((x) => {
        Object.defineProperty(x, s, Object.getOwnPropertyDescriptor(x, name))
        delete x[name]
      })
    },
    isExcel (file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },
    async getPdf () {
      this.resError = false
      const pdf = await getPdfApi(this.$router.history.current.query.fid)
      if (pdf?.data?.success) {
        const arr = []
        pdf.data.data.forEach((x) => {
          const nameSplit = x.split('/')
          arr.push({
            name: nameSplit[nameSplit.length - 1],
            value: x
          })
        })
        this.pdfArray = arr
        if (this.pdfArray.length === 0) this.resError = true
      }
    },
    async getcsv (str) {
      this.resError = false
      const fileName = this.$router.history.current.query.siteName + str
      const CSV = await getCsvApi(fileName)
      if (CSV?.data?.success) {
        this.csvName = CSV.data.data.fileName
        this.tableData = CSV.data.data.csvData
        this.tableHeader = CSV.data.data.csvHeader
      } else {
        this.resError = true
      }
    },
    async updateCsv () {
      const csvHeader = this.tableHeader
      const csvData = this.tableData
      const update = await updateCsvApi(this.csvName, csvHeader, csvData)
      if (update?.data?.success) {
        this.$swal('儲存成功')
      }
    },
    async checkDeletePdf (pdfName) {
      this.$swal
        .fire({
          title: `確定要刪除?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `確定`,
          denyButtonText: `否`
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deletePdf(pdfName)
          }
        })
    },
    async deletePdf (pdfName) {
      const deletePdf = await deletePdfApi(
        this.$router.history.current.query.siteName,
        pdfName
      )
      if (deletePdf?.data?.success) {
        this.$swal('刪除成功')
        this.getPdf()
      }
    }
  },
  created () {
    this.getcsv('_串列資料.json')
  }
}
</script>
<style lang="scss" scoped>
span {
  padding: 0px;
  margin: 0px;
}
table {
  width: 100% !important;
}
// table tr:nth-child(odd) {
//   /* background-color: #f8f8f8; */
// }
table th {
  padding: 3px;
  text-align: center;
  min-width: 205px;
  border: solid 1px rgb(236, 236, 236);
  /* color: aliceblue; */
  color: #110b0b;
  /* background: var(--acmeTableTitle); */
  background-color: rgba(204, 207, 196, 0.178);
}
table td {
  padding: 5px 30px;
  text-align: center;
}
// table tr {
//   /* background-color: #cfeaff; */
// }

table td {
  font-size: 17px;
  font-weight: 700;
  border: solid 1px rgb(236, 236, 236);
}
.kpiSettingTable {
  table th {
    min-width: auto !important;
  }
}
.kpiTableWrap {
  overflow-x: auto;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
</style>
<style lang="scss" scoped>
@import "../assets/scss/DataExcel.scss";
</style>
